import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "lib-flexible/flexible"
//

/** vant框架的引入 */
import 'vant/lib/index.css';
import { Button } from 'vant';
import { NavBar } from 'vant';
import { Tabbar, TabbarItem } from 'vant';
import { Image as VanImage } from 'vant';
import { Col, Row } from 'vant';
import { Icon } from 'vant';
import { Cell, CellGroup,Field,Form } from 'vant';
import { ActionSheet } from 'vant';
import { Tab, Tabs } from 'vant';
import { Card,Stepper} from 'vant';
import { NoticeBar } from 'vant';
import { Checkbox, CheckboxGroup } from 'vant';
import { RadioGroup, Radio,Area,Popup } from 'vant';
import { Space,Sticky } from 'vant';
import { Step, Steps } from 'vant';
import { Divider,Dialog  } from 'vant';
import { Toast,FloatingBubble } from 'vant';
import { CountDown,Overlay  } from 'vant';
import { PullRefresh } from 'vant';
import { Loading } from 'vant';
import { Swipe, SwipeItem } from 'vant';
import { ImagePreview,Popover  } from 'vant';
import {Skeleton, SkeletonTitle, SkeletonImage, SkeletonAvatar, SkeletonParagraph,} from 'vant';
import { Picker } from 'vant';





const app = createApp(App);
app.use(store);
app.use(router);

/** vant框架的引入 */
app.use(Button);
app.use(NavBar);
app.use(Tabbar);app.use(TabbarItem);
app.use(VanImage);
app.use(Col);app.use(Row);
app.use(Icon);
app.use(Cell);app.use(CellGroup);app.use(Field);app.use(Form);
app.use(ActionSheet);
app.use(Tab);app.use(Tabs);
app.use(Card);app.use(Stepper);
app.use(NoticeBar);
app.use(Checkbox);app.use(CheckboxGroup);
app.use(Radio);app.use(RadioGroup);app.use(Area);app.use(Popup);
app.use(Space);app.use(Sticky);
app.use(Step);app.use(Steps);
app.use(Divider);app.use(Dialog);
app.use(Toast);app.use(FloatingBubble);
app.use(CountDown);app.use(Overlay);
app.use(PullRefresh);app.use(Loading);
app.use(Swipe);app.use(SwipeItem);
app.use(ImagePreview);app.use(Popover);
app.use(Skeleton);
app.use(SkeletonTitle);
app.use(SkeletonImage);
app.use(SkeletonAvatar);
app.use(SkeletonParagraph);
app.use(Picker);



app.mount('#app')
