<template>
  <van-tabbar v-if="isShowTabbar" v-model="active" route border
              :style="[phoneClass||$store.state.phoneClass?'padding: 10px 0 20px 0':'padding: 6px 0']">
    <van-tabbar-item to="/" :class="[$route.path==='/complete'?'colorBlue':'']">
      <template #icon>
        <van-icon name="records-o" size="24"/>
      </template>
      我要委托
    </van-tabbar-item>
    <van-tabbar-item to="/list" :class="[$route.path==='/detail'||$route.path==='/pdf'?'colorBlue':'']">
      <template #icon>
        <van-icon name="bars" size="24"/>
      </template>
      已提交
    </van-tabbar-item>
    <van-tabbar-item to="/person" icon="contact">
      <template #icon>
        <van-icon name="contact-o" size="24"/>
      </template>
      我的
    </van-tabbar-item>
  </van-tabbar>

  <router-view/>
</template>

<script setup>
import {ref} from "vue";
import router from "@/router";
import $ from 'jquery';

const active = ref(0);// acticve: 控制tabbar选中的索引 从0开始
import {useStore} from "vuex";


console.log(window.location.origin)
console.log(window.location.pathname)
console.log(window.location.search)
import {getOpenId} from "@/api/login";
import {existUser, manualLogin} from "./api/login";
import {jumpTo} from "./api/routerJS";

//本地测试
// localStorage.setItem("wxCscyUser", '{"collectMP":true,"createTime":"2024-04-15 22:39:22","disabled":false,"id":1713191611875724,"imgUrl":"https://thirdwx.qlogo.cn/mmopen/vi_32/HDZF5RIPAglpcnwtYN708qN9BKWMia2lBqGelYKPpKen0Dp4g9A7bkzayGrzFZIf9rPjlYgAITt8nLZxFVIgVCg/132","modifyTime":"2024-10-30 16:57:12","name":"Zoro","openid":"ot73HwZNJIFya85t-oInrEsyaxYs","phone":"13629763511","realUserId":1641546767201994,"refreshToken":"79_4OkhRaDDnetIczkkkC9sWsvZnqCDv34eYcZkzwGhGdUhKQ9FlByFl-gI8KpYM9PUnmgSUqIV5hB6GVoAOWozvFPWSokuCjhk1E1MI3cbhQo","sourceType":"Direct","unionid":"oO1G55tq_KdIfn2Dt_mgxO2M8Eso","username":"用户1253905269522174931"}');

const params = new URLSearchParams(window.location.search);
console.log(params)
let code = params.get('code');// 这是微信返回的code
console.log(code, '44444444444')
let state = params.get('state');// 登录成功后的参数
console.log(state)
// 不登陆白名单
let whiteList = ['/logoff'];

if (whiteList.indexOf(window.location.pathname) === -1) {
  //获取存在本地的已授权用户信息
  let item = localStorage.getItem("wxCscyUser");
  //如果本地授权用户信息为空
  if (!item) {
    //如果有code
    if (code) {
      console.log(code + "======================" + state)
      // alert("code:"+code)
      //请求获取网页授权链接
      getOpenId(code, state).then((res) => {
        if (res.success) {
          let data = res.data;
          console.log("登录成功后..." + data)
          // 处理后端给的用户数据，保存到localstorage
          if (res.success) {
            if (data.disabled) {
              // 注销了
              window.location.href = 'https://wechat.chaxin.org.cn/logoff';
            } else {
              //把获取的授权后的用户信息存到本地
              localStorage.setItem("wxCscyUser", JSON.stringify(data));
              //获取当前页面地址
              let pathname = window.location.pathname;
              if (data.unionid) {
                // 授权了，刷新页面
                window.location.href = 'https://wechat.chaxin.org.cn' + pathname + state;
              } else {
                // 如果没授权，不刷新页面// if (pathname==='/list'){
                jumpTo('/introduce')
                // }
              }

            }
          }
        }
      })
    } else {
      //如果没有code，进入未登录时访问的页面
      // alert("非403跳转第一次未登录")
      // 未登录时访问的页面
      let destination = window.location.pathname;
      let state = window.location.search;
      let loginHref = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx7b7c22d24bb08527&redirect_uri=https://wechat.chaxin.org.cn${destination}&response_type=code&scope=snsapi_base&state=${state}#wechat_redirect`
      console.log(loginHref)
      window.location.href = loginHref;
    }
  } else {
    //如果本地授权用户信息有值
    // 查后端有没有当前用户
    existUser(JSON.parse(item).openid).then(res => {
      console.log(res)
      let data = res.data;
      //如果有用户
      if (data) {
        //有用户且已登录
        if (res.loginFlag) {
          // 有用户，登录了
          if (data.disabled) {
            // 注销了
            window.location.href = 'https://wechat.chaxin.org.cn/logoff';
          }
        } else {
          // 有用户,没登陆
          // 去触发手动登录接口
          manualLogin(JSON.parse(item)).then((res) => {
            if (res.success) {
              // 更新localstorage
              let data = res.data;
              localStorage.setItem("wxCscyUser", JSON.stringify(data));

              if (data.disabled) {
                // 注销了
                window.location.href = 'https://wechat.chaxin.org.cn/logoff';
              }
            }
          })
        }
      } else {
        // 没有用户，删本地缓存
        localStorage.removeItem("wxCscyUser")
        // 发起静默
        let destination = window.location.pathname;
        let state = window.location.search;
        let loginHref = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx7b7c22d24bb08527&redirect_uri=https://wechat.chaxin.org.cn${destination}&response_type=code&scope=snsapi_base&state=${state}#wechat_redirect`
        console.log(loginHref)
        window.location.href = loginHref;
      }
    })
  }
}

/**
 * 控制tabbar的显示与隐藏
 * @type {Ref<UnwrapRef<boolean>>}
 */
const isShowTabbar = ref(true);
/**
 * 通过路由跳转，实时获取页面地址，介绍页和手机绑定页面隐藏tabbar
 */

const isIOS = ref(false);
const userAgent = window.navigator.userAgent;
if (userAgent.indexOf("Android") > -1) {
  isIOS.value = false;
} else if (userAgent.indexOf("iPhone") > -1) {
  isIOS.value = true;
}

const phoneClass = ref(true);  //是否为ios 默认true为安卓机
let store = useStore();


router.beforeEach((to, from) => {
  if (to.path !== '/' || (to.path === '/' && code)) {
    if (isIOS.value) {   //苹果手机
      phoneClass.value = false;
      store.state.phoneClass = phoneClass.value;
    } else {
      phoneClass.value = true;
      store.state.phoneClass = phoneClass.value;
    }
  }
  $('.listTabbar').addClass('colorBlue');
  if (from.path === '/introduce') {
    isShowTabbar.value = true;
  }
  if (to.path === '/introduce' || to.path === '/logoff') {
    isShowTabbar.value = false;
  }
})


</script>

<style lang="scss">
@import "assets/css/common.css";

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.colorBlue {
  color: var(--van-tabbar-item-active-color) !important;
}
</style>
